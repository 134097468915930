import React from 'react';
import {
  IntlContextConsumer,
  Link,
  useIntl,
  FormattedDate,
  FormattedRelativeTime,
} from 'gatsby-plugin-intl';
import { selectUnit } from '@formatjs/intl-utils';

const Card = ({ article, readButton, variant }) => {
  const currentDate = new Date();
  const postDate = new Date(article.postdate);
  const select = selectUnit(postDate);

  const yearMatch = postDate.getFullYear() === currentDate.getFullYear();

  const formatSettings = {
    value: postDate,
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  return (
    <div key={article.id} className="blog_card">
      <p className="blog_card__description">{article.excerpt}</p>
      <span className="blog_card__date">
        {yearMatch ? (
          <FormattedRelativeTime {...select} />
        ) : (
          <FormattedDate {...formatSettings} />
        )}
      </span>
      <Link
        to={`/${variant}/${article.slug}`}
        className="blog_card__link"
        style={{
          backgroundImage: `url(${
            article.coverImage
              ? article.coverImage.fluid.src
              : article.linkCoverImage
          })`,
        }}
      >
        <div className="blog_card__title">
          <h2>{article.title}</h2>
          <button className="blog_card__button">{readButton}</button>
        </div>
      </Link>
    </div>
  );
};

export default Card;
