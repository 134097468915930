import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../templates/Layout';
import Card from '../components/Card';

const BlogPage = ({ data = {} }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const articlesList = data.allDatoCmsArticle.edges.map((edge) => edge.node);
  const searchData = (e) => {
    setSearchQuery(e.target.value);
    setSearchResults(
      articlesList.filter((article) => {
        if (
          article.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
          article.excerpt.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return article;
        }
      })
    );
  };
  const queryResults = searchQuery === '' ? articlesList : searchResults;

  data.datoCmsSetting.searchValue = searchQuery;
  data.datoCmsSetting.searchOnChange = searchData;

  return (
    <Layout siteData={data.datoCmsSetting} search>
      {/* <Masonry className='showcase'>
			{data.allDatoCmsArticle.edges.map(({ node: article }) => (
				<Card article={article} readButton={data.datoCmsSetting.readButton} />
			))}
		</Masonry> */}
      <div className="blog__grid">
        {queryResults.map(
          (article) =>
            article && (
              <Card
                variant={"articles"}
                article={article}
                readButton={data.datoCmsSetting.readButton}
              />
            )
        )}
      </div>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query IndexQuery($locale: String!) {
    allDatoCmsArticle(
      sort: { fields: [postdate], order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          postdate
          linkCoverImage
          coverImage {
            fluid(maxWidth: 650, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
    datoCmsSetting(locale: { eq: $locale }) {
      readButton
      searchText
      showSearch
    }
  }
`;
